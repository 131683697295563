export const uuiDaySelection = {
    container: 'uui-calendar-container',
    content: 'uui-calendar-content',
    weekdaysContainer: 'uui-calendar-weekdays-container',
    weekday: 'uui-calendar-weekday',
    days: 'uui-calendar-days',
    dayCell: 'uui-calendar-day-cell',
    day: 'uui-calendar-day',
    currentDay: 'uui-calendar-current-day',
    selectedDay: 'uui-calendar-selected-day',
    filteredDay: 'uui-calendar-filtered-day',
    previousMonthEmptyDay: 'uui-calendar-previous-month-empty-day',
    clickableDay: 'uui-calendar-clickable-day',
    dayWrapper: 'uui-calendar-day-wrapper',
    holiday: 'uui-calendar-day-holiday',
} as const;
