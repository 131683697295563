export const COLOR_MAP: Record<string, string> = {
    // semantic colors
    primary: 'var(--uui-primary-50)',
    accent: 'var(--uui-accent-50)',
    secondary: 'var(--uui-secondary-50)',
    neutral: 'var(--uui-neutral-50)',
    critical: 'var(--uui-critical-50)',
    info: 'var(--uui-info-50)',
    success: 'var(--uui-success-50)',
    warning: 'var(--uui-warning-50)',
    error: 'var(--uui-error-50)',

    // theme colors
    blue: 'var(--blue-50)',
    green: 'var(--green-50)',
    amber: 'var(--amber-50)',
    red: 'var(--red-50)',
    sky: 'var(--sky-50)',
    grass: 'var(--grass-50)',
    sun: 'var(--sun-50)',
    fire: 'var(--fire-50)',
    // additional colors
    gray: 'var(--uui-neutral-50)', // TODO: Do we need this?
    white: 'var(--uui-neutral-0)',
    yellow: 'var(--yellow-50)',
    orange: 'var(--orange-50)',
    fuchsia: 'var(--fuchsia-50)',
    purple: 'var(--purple-50)',
    violet: 'var(--violet-50)',
    cobalt: 'var(--cobalt-50)',
    cyan: 'var(--cyan-50)',
    mint: 'var(--mint-50)',

    gray5: 'var(--gray5)',
    gray10: 'var(--gray10)',
    gray20: 'var(--gray20)',
    gray30: 'var(--gray30)',
    gray40: 'var(--gray40)',
    gray50: 'var(--gray50)',
    gray60: 'var(--gray60)',
    gray70: 'var(--gray70)',
    gray80: 'var(--gray80)',
    gray90: 'var(--gray90)',

    night50: 'var(--night50)',
    night100: 'var(--night100)',
    night200: 'var(--night200)',
    night300: 'var(--night300)',
    night400: 'var(--night400)',
    night500: 'var(--night500)',
    night600: 'var(--night600)',
    night700: 'var(--night700)',
    night800: 'var(--night800)',
    night900: 'var(--night900)',
};
